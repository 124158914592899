import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { CUSTOM_COLORS } from '../components/gradientDefs';
import { useTheme, useMediaQuery } from '@mui/material';

const MonthlyChart = ({ monthlyData, monthlyLoading, selectedSensor, selectedYear, error, showLabels }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const themeMode = useSelector(({ global }) => global.mode);
    const [disabledBars, setDisabledBars] = useState({});

    const selectedYearValue = selectedYear instanceof Date ? selectedYear.getFullYear() : selectedYear.$y || selectedYear;

    const isDataAvailable = monthlyData && Object.keys(monthlyData).length > 0;
    const isSensorDataAvailable = isDataAvailable && monthlyData[selectedSensor];
    const isYearDataAvailable = isSensorDataAvailable && monthlyData[selectedSensor][selectedYearValue];

    const chartData = useMemo(() => {
        if (!isYearDataAvailable) return [];

        const yearData = monthlyData[selectedSensor][selectedYearValue];
        const months = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));

        const uniqueKeys = new Set();

        const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

        const data = months.map((month, index) => {
            const entry = { month: monthNames[index] };

            if (yearData.hasOwnProperty(month)) {
                Object.entries(yearData[month]).forEach(([categoryName, value]) => {
                    if (categoryName !== 'Total') {
                        entry[categoryName] = value;
                        uniqueKeys.add(categoryName);
                    }
                });
            } else {
                Object.keys(yearData[Object.keys(yearData)[0]]).forEach(categoryName => {
                    if (categoryName !== 'Total') {
                        entry[categoryName] = 0;
                    }
                });
            }

            return entry;
        });

        return data;
    }, [isYearDataAvailable, monthlyData, selectedSensor, selectedYearValue]);

    // Get all unique bar names from the monthly data, excluding 'Total'
    const allBarNames = useMemo(() => {
        const barNames = new Set();
        if (monthlyData && monthlyData[selectedSensor]) {
            Object.values(monthlyData[selectedSensor]).forEach((yearData) => {
                Object.values(yearData).forEach((monthData) => {
                    Object.keys(monthData).forEach((key) => {
                        if (key !== 'Total') {
                            barNames.add(key);
                        }
                    });
                });
            });
        }
        return Array.from(barNames).sort((a, b) => {
            const aNum = parseFloat(a);
            const bNum = parseFloat(b);
            if (!isNaN(aNum) && !isNaN(bNum)) {
                return aNum - bNum;
            }
            return a.localeCompare(b);
        });
    }, [monthlyData, selectedSensor]);

    // Check if all values are zero
    const allDataZero = useMemo(() => {
        return chartData.every((data) =>
            allBarNames.every((key) => data[key] === 0)
        );
    }, [chartData, allBarNames]);

    // Shuffle colors for consistent assignment
    const shuffleColors = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const uniqueColors = useMemo(() => {
        const shuffledColors = shuffleColors([...CUSTOM_COLORS]);
        return allBarNames.reduce((acc, barName, index) => {
            acc[barName] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});
    }, [allBarNames]);

    // Calculate dynamic interval for XAxis ticks based on the number of months
    const calculateInterval = () => {
        const dataLength = chartData.length;
        if (dataLength <= 6) return 0;
        if (dataLength <= 12) return 1;
        return Math.floor(dataLength / 6);
    };

    const interval = calculateInterval();

    // Check for 404 error
    if (error && error.status === 404) {
        return <NoDataAvailableMessage report={true} />;
    }

    if (monthlyLoading || !monthlyData) {
        return <Loading report={true} />;
    }

    if (chartData.length === 0 || allDataZero) {
        return <NoDataAvailableMessage report={true} />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (legendItem) => {
        const clickedBar = legendItem.dataKey;
        setDisabledBars((prev) => ({
            ...prev,
            [clickedBar]: !prev[clickedBar],
        }));
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={chartData}
                margin={isSmallScreen ? { top: 20, right: 10, bottom: 50, left: 20 } : { top: 20, right: 30, bottom: 60, left: 20 }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis
                    dataKey="month"
                    angle={isSmallScreen ? -50 : 0}
                    textAnchor={isSmallScreen ? 'end' : 'middle'}
                    interval={interval}
                    tick={{ fill: textColor, fontSize: isSmallScreen ? 10 : 12 }}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    contentStyle={{
                        backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                        border: 'none',
                        borderRadius: '8px',
                        color: textColor,
                    }}
                    cursor={{ fill: 'grey', opacity: 0.1 }}
                />
                <Legend
                    align="center"
                    wrapperStyle={{ color: textColor, paddingTop: isSmallScreen ? 30 : 10 }}
                    onClick={handleLegendClick}
                    formatter={(value) => (
                        <span
                            style={{
                                color: disabledBars[value] ? '#888' : textColor,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                />
                {allBarNames.map((key) => (
                    <Bar
                        key={key}
                        dataKey={key}
                        fill={uniqueColors[key]}
                        name={key}
                        hide={disabledBars[key]}
                    >
                        {showLabels && (
                            <LabelList
                                dataKey={key}
                                position="top"
                                fill={textColor}
                                fontSize={isSmallScreen ? 10 : 12}
                                formatter={(value) => (value > 0 ? value.toLocaleString() : '')}
                            />
                        )}
                    </Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default MonthlyChart;