import React, { useMemo, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CompanyDetails from './CompanyDetails';
import CompanyUsersTable from './CompanyUsersTable';
import ConnectedDevicesAndSitesTable from './ConnectedDevicesAndSitesTable';
import LicenseTable from './LicenseTable';

const CompanyContent = ({ companyData, refetch, selectedCompany, selectedCompanyId, userData = [], devicesData = [], licenseData = [], isSmallScreen }) => {
    const theme = useTheme();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const filteredDevicesData = useMemo(() => {
        if (!selectedCompanyId || !devicesData) {
            return devicesData;
        } else {
            return devicesData.filter(device => device.company._id === selectedCompanyId);
        }
    }, [devicesData, selectedCompanyId]);


    const sortedUserData = useMemo(() => {
        const sortedUsers = [...userData].sort((a, b) => {
            if (order === 'asc') {
                return a[orderBy] > b[orderBy] ? 1 : -1;
            } else {
                return b[orderBy] > a[orderBy] ? 1 : -1;
            }
        });
        return sortedUsers;
    }, [userData, orderBy, order]);

    const sortedDeviceData = useMemo(() => {
        const sortedDevices = [...filteredDevicesData].sort((a, b) => {
            if (order === 'asc') {
                return a[orderBy] > b[orderBy] ? 1 : -1;
            } else {
                return b[orderBy] > a[orderBy] ? 1 : -1;
            }
        });
        return sortedDevices;
    }, [filteredDevicesData, orderBy, order]);

    const sortedLicenseData = useMemo(() => {
        const processedData = licenseData.map(license => {
            const status = new Date(license.endDate) > new Date() ? 'Active' : 'Inactive';
            const remainingDays = Math.max(0, Math.floor((new Date(license.endDate) - new Date()) / (1000 * 60 * 60 * 24)));

            return {
                ...license,
                status,
                remainingDays,
            };
        });

        return processedData.sort((a, b) => {
            if (order === 'asc') {
                return a[orderBy] > b[orderBy] ? 1 : -1;
            } else {
                return b[orderBy] > a[orderBy] ? 1 : -1;
            }
        });
    }, [licenseData, orderBy, order]);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(property);
    };

    return (
        <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="160px"
            gap="20px"
            pb="20px"
        >
            <CompanyDetails
                companyData={companyData}
                selectedCompany={selectedCompany}
                refetch={refetch}
            />

            <Box
                gridColumn={isSmallScreen ? "span 12" : "span 6"}
                gridRow="span 2"
                backgroundColor={theme.palette.background.paper}
                borderRadius="15px"
                position="relative"
                p="4%"
                overflow="auto"
                sx={{
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography
                    variant="h3"
                    sx={{ color: theme.palette.text.white, mb: "2%", fontWeight: 'bold' }}
                >
                    COMPANY USERS
                </Typography>
                <CompanyUsersTable
                    sortedUserData={sortedUserData}
                    orderBy={orderBy}
                    order={order}
                    createSortHandler={createSortHandler}
                />
            </Box>

            <Box
                gridColumn={isSmallScreen ? "span 12" : "span 6"}
                gridRow="span 2"
                backgroundColor={theme.palette.background.paper}
                borderRadius="0.55rem"
                position="relative"
                p="4%"
                overflow="auto"
                sx={{
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography
                    variant="h3"
                    sx={{ color: theme.palette.text.white, mb: "2%", fontWeight: 'bold' }}
                >
                    CONNECTED DEVICES & SITES
                </Typography>
                <ConnectedDevicesAndSitesTable
                    sortedDeviceData={sortedDeviceData}
                    orderBy={orderBy}
                    order={order}
                    createSortHandler={createSortHandler}
                />
            </Box>
            <Box
                gridColumn={isSmallScreen ? "span 12" : "span 6"}
                gridRow="span 2"
                backgroundColor={theme.palette.background.paper}
                borderRadius="0.55rem"
                position="relative"
                p="4%"
                overflow="auto"
                sx={{
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography
                    variant="h3"
                    sx={{ color: theme.palette.text.white, mb: "2%", fontWeight: 'bold' }}
                >
                    LICENSES
                </Typography>
                <LicenseTable
                    sortedLicenseData={sortedLicenseData}
                    orderBy={orderBy}
                    order={order}
                    createSortHandler={createSortHandler}
                />
            </Box>
        </Box>
    );
};

export default CompanyContent;