import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import GrowthOverviewChart from './GrowthOverviewChart';

const GrowthChart = ({ yearsOfCompanyCreation, isLoading }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    return (
        <Box
            gridColumn={isSmallScreen ? "span 12" : isTablet ? "span 12" : "span 8"}
            gridRow="span 3"
            backgroundColor={theme.palette.background.paper}
            borderRadius="0.55rem"
            display="flex"
            flexDirection="column"
            position="relative"
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography variant='h3' sx={{ color: theme.palette.text.white, mt: "2%", ml: "2%", fontWeight: "bold"}}>
                YEARLY REGISTRATION
            </Typography>
            <Box
                sx={{
                    height: "calc(100% - 50px)",
                    width: "100%",
                    position: "relative",
                }}
            >
                <GrowthOverviewChart
                    yearsOfCompanyCreation={yearsOfCompanyCreation}
                    isLoading={isLoading}
                />
            </Box>
        </Box>
    );
};

export default GrowthChart;
