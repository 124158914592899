import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, useTheme, Slide, FormControl, InputLabel, Select, MenuItem, Button, Grid } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddSiteDialogBox = ({ openDialog, handleCloseDialog, handleInputChange, handleAddSite, siteData, mainLocationsInQatar, companyOptions }) => {
    const theme = useTheme();

    // Ensure siteData.company is initialized to a valid value
    const companyValue = siteData.company || '';

    return (
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            onClose={handleCloseDialog}
            aria-describedby="add-site-dialog-description"
            PaperProps={{
                sx: { 
                    backgroundColor: theme.palette.background.paper, 
                    width: '600px', // Match the width of AddCompanyDialogBox
                    padding: theme.spacing(2), // Add padding for consistency
                }
            }}
        >
            <DialogTitle sx={{ color: theme.palette.text.white }} variant="h2" fontWeight="bold">
                Add New Site
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}> {/* Use Grid for layout */}
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="siteName"
                            name="name"
                            label="Name of Site"
                            fullWidth
                            value={siteData.name}
                            onChange={handleInputChange}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel id="company">Company</InputLabel>
                            <Select
                                labelId="company"
                                id="company"
                                name="company"
                                value={companyValue} // Use the fallback value
                                label="Company"
                                onChange={handleInputChange}
                            >
                                {companyOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel id="location">Location</InputLabel>
                            <Select
                                labelId="location"
                                id="location"
                                name="location"
                                label="Location"
                                value={siteData.location || ''} // Fallback for undefined
                                onChange={handleInputChange}
                            >
                                {mainLocationsInQatar.map((location) => (
                                    <MenuItem key={location.name} value={location.name}>
                                        {location.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            id="latitude"
                            name="latitude"
                            label="Latitude"
                            fullWidth
                            value={siteData.latitude}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            id="longitude"
                            name="longitude"
                            label="Longitude"
                            fullWidth
                            value={siteData.longitude}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: theme.spacing(2) }}>
                <Button
                    onClick={handleCloseDialog}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.text.white,
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: theme.palette.text.white,
                        },
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleAddSite}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.text.white,
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: theme.palette.text.white,
                        },
                    }}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddSiteDialogBox;