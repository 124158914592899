import React from 'react';
import { Box, Typography, useTheme, Button } from '@mui/material';
import Header from '../../components/Header';
import CompanySelector from '../../components/CompanySelector';
import { AddBoxOutlined } from '@mui/icons-material';

const AllUsersHeader = ({ selectedCompany, handleCompanyChange, companyOptions, handleOpenDialog, isSmallScreen }) => {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" gap="1rem">
            {isSmallScreen ? (
                <>
                    <Box 
                        display="flex" 
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="0.5rem"
                        sx={{
                            boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Header title="ALL USERS" subtitle="View all registered user details for companies" />
                    </Box>
                    <Box 
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="1rem"
                        gap="1rem"
                        sx={{
                            boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <CompanySelector 
                            selectedCompany={selectedCompany}
                            handleCompanyChange={handleCompanyChange}
                            companyOptions={companyOptions}
                        />
                        <Button
                            onClick={handleOpenDialog}
                            sx={{
                                color: theme.palette.text.black,
                                backgroundColor: theme.palette.text.white,
                                minWidth: '150px',
                                height: '50px',
                                '&:hover': {
                                    backgroundColor: theme.palette.text.white,
                                },
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                        >
                            <AddBoxOutlined sx={{ marginRight: "8px" }} />
                            Add New User
                        </Button>
                    </Box>
                    <Box
                        backgroundColor={theme.palette.background.paper}
                        borderRadius='15px'
                        p='0.5rem'
                        sx={{
                            boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Typography
                            variant={isSmallScreen ? "h3" : "h2"}
                            component="div"
                            sx={{
                                color: theme.palette.text.white,
                                fontWeight: "bold",
                                textAlign: "left",
                                ml: isSmallScreen ? '0.5rem' : '0',
                            }}
                        >
                            {selectedCompany}
                        </Typography>
                    </Box>
                </>
            ) : (
                <>
                    <Box 
                        display="flex" 
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="1rem"
                        sx={{
                            boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Header title="ALL USERS" subtitle="View all registered user details for companies" />
                        <Box display="flex" alignItems="center" gap="1rem">
                            <CompanySelector 
                                selectedCompany={selectedCompany}
                                handleCompanyChange={handleCompanyChange}
                                companyOptions={companyOptions}
                            />
                            <Button
                                onClick={handleOpenDialog}
                                sx={{
                                    color: theme.palette.text.black,
                                    backgroundColor: theme.palette.text.white,
                                    minWidth: '150px',
                                    height: '50px',
                                    '&:hover': {
                                        backgroundColor: theme.palette.text.white,
                                    },
                                    '&:focus': {
                                        outline: 'none',
                                    },
                                }}
                            >
                                <AddBoxOutlined sx={{ marginRight: "8px" }} />
                                Add New User
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={isSmallScreen ? '0' : '1rem'}
                        backgroundColor={theme.palette.background.paper}
                        borderRadius="15px"
                        sx={{
                            boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Typography
                            variant={isSmallScreen ? "h3" : "h2"}
                            component="div"
                            sx={{
                                color: theme.palette.text.white,
                                fontWeight: "bold",
                                textAlign: "left",
                                ml: isSmallScreen ? '0.5rem' : '0',
                            }}
                        >
                            {selectedCompany}
                        </Typography>
                    </Box>
                </> 
            )}
        </Box>
    );
};

export default AllUsersHeader;
