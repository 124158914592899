import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { CUSTOM_COLORS } from '../../charts/components/gradientDefs';

const GrowthOverviewChart = ({ yearsOfCompanyCreation, isLoading }) => {
    const themeMode = useSelector(({ global }) => global.mode);

    // Check if loading or data is unavailable
    if (isLoading || !yearsOfCompanyCreation || Object.keys(yearsOfCompanyCreation).length === 0) {
        return (
            <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
            </div>
        );
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    // Get the last five years as strings
    const lastFiveYears = Array.from({ length: 5 }, (_, index) => {
        const currentYear = new Date().getFullYear();
        return (currentYear - index).toString();
    });

    // Process data for chart
    const chartData = lastFiveYears.map(year => ({
        year,
        companies: yearsOfCompanyCreation[year] || 0,
    }));

    // Get keys (categories) from the data
    const keys = ['companies']; // Since we only have one key in this case

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={chartData}
                margin={{ top: 30, right: 20, bottom: 20, left: 20 }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis
                    dataKey="year"
                    tick={{ fill: textColor }}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    contentStyle={{
                        backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                        border: 'none',
                        borderRadius: '8px',
                        color: textColor,
                    }}
                    cursor={{ fill: 'grey', opacity: 0.1 }}
                />
                {keys.map((key, index) => (
                    <Bar
                        key={key}
                        dataKey={key}
                        fill={CUSTOM_COLORS[index % CUSTOM_COLORS.length]}
                        radius={[4, 4, 0, 0]}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default GrowthOverviewChart;