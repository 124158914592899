import React, { useState, useCallback } from 'react';
import WeeklyHeader from './WeeklyHeader';
import useSelectedSensor from '../../hooks/useSelectedSensor';
import useSensorOptions from '../../hooks/useSensorOptions';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import WeeklyContent from './WeeklyContent';
import { Box, Grid, Paper, useTheme, useMediaQuery } from '@mui/material';
import { useWeeklyDataQuery, useAdultnChildWeeklyDataQuery, useGenderWeeklyDataQuery, useGetAddonByDeviceQuery } from '../../services/api';
import { useSelector } from 'react-redux';
import WeeklyFilterBox from './WeeklyFilterBox';
import WeeklyLabel from './WeeklyLabel';

const Weekly = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { company, role } = useSelector((state) => state.global);

    const { companyOptions } = useCompanyOptions();
    const isSuperOrPowerAdmin = role === 'Super Admin' || role === 'Power Admin';
    const { selectedCompany, selectedCompanyId, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const sensorOptions = useSensorOptions(isSuperOrPowerAdmin ? selectedCompanyId : company);
    const { selectedSensor, handleSensorChange, getSelectedSensorLabel } = useSelectedSensor(sensorOptions, isSuperOrPowerAdmin ? selectedCompany : company);

    const [showLabels, setShowLabels] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [selectedMonthYear, setSelectedMonthYear] = useState({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
    });

    const { data: addonData } = useGetAddonByDeviceQuery(selectedSensor, {
        skip: !selectedSensor,
    });

    const availableTabs = [
        { label: "TOTAL", value: 0 },
        addonData?.[0]?.adultnchild && { label: "ADULT & CHILD", value: 1 },
        addonData?.[0]?.gender && { label: "GENDER", value: 2 },
        addonData?.[0]?.mask && { label: "MASK", value: 3 },
    ].filter(Boolean);

    const { data: weeklyData, isLoading: weeklyLoading, error: weeklyError } = useWeeklyDataQuery(
        { mac: selectedSensor, month: selectedMonthYear.month, year: selectedMonthYear.year },
        { skip: tabValue !== 0 || !selectedSensor }
    );

    const { data: adultChildData, isLoading: adultChildLoading, error: adultChildError } = useAdultnChildWeeklyDataQuery(
        { mac: selectedSensor, month: selectedMonthYear.month, year: selectedMonthYear.year },
        { skip: tabValue !== 1 || !selectedSensor }
    );

    const { data: genderData, isLoading: genderLoading, error: genderError } = useGenderWeeklyDataQuery(
        { mac: selectedSensor, month: selectedMonthYear.month, year: selectedMonthYear.year },
        { skip: tabValue !== 2 || !selectedSensor }
    );

    // Determine which data to use based on the selected tab
    const processedData = availableTabs[tabValue]?.value === 0 ? weeklyData 
                        : availableTabs[tabValue]?.value === 1 ? adultChildData 
                        : availableTabs[tabValue]?.value === 2 ? genderData 
                        : null;

    const chartIsLoading = availableTabs[tabValue]?.value === 0 ? weeklyLoading 
                            : availableTabs[tabValue]?.value === 1 ? adultChildLoading 
                            : availableTabs[tabValue]?.value === 2 ? genderLoading 
                            : null;

    const error = availableTabs[tabValue]?.value === 0 ? weeklyError 
                : availableTabs[tabValue]?.value === 1 ? adultChildError 
                : availableTabs[tabValue]?.value === 2 ? genderError 
                : null;

    const handleShowLabelsChange = useCallback((event) => {
        setShowLabels(event.target.checked);
    }, []);

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
    };

    // JSX
    return (
        <Box m={isSmallScreen ? "0.5rem" : "1rem 2.5rem"}>
            <Grid container spacing={2} paddingBottom={3}>
                <Grid item xs={12} md={10}>
                    <WeeklyHeader isSmallScreen={isSmallScreen} />
                    <WeeklyLabel
                        sensorLabel={getSelectedSensorLabel()}
                        isSmallScreen={isSmallScreen}
                        tabValue={tabValue}
                        onTabChange={handleTabChange}
                        addonData={addonData}
                    />
                    <WeeklyContent
                        selectedSensor={selectedSensor}
                        showLabels={showLabels}
                        processedData={processedData}
                        selectedMonth={selectedMonthYear.month}
                        selectedYear={selectedMonthYear.year}
                        error={error}
                        isLoading={chartIsLoading}
                        isSmallScreen={isSmallScreen}
                        sensorLabel={getSelectedSensorLabel()}
                        tabValue={tabValue}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Paper sx={{ position: 'sticky', top: 20, height: 'auto', overflow: 'auto', borderRadius: '15px', boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)' }}>
                        <WeeklyFilterBox
                            sensorLabel={getSelectedSensorLabel()}
                            isSmallScreen={isSmallScreen}
                            showLabels={showLabels}
                            onShowLabelsChange={handleShowLabelsChange}
                            sensorOptions={sensorOptions}
                            selectedSensor={selectedSensor}
                            handleSensorChange={handleSensorChange}
                            companyOptions={companyOptions}
                            selectedCompany={isSuperOrPowerAdmin ? selectedCompany : null}
                            handleCompanyChange={isSuperOrPowerAdmin ? handleCompanyChange : null}
                            isSuperAdmin={isSuperOrPowerAdmin}
                            selectedMonthYear={selectedMonthYear}
                            setSelectedMonthYear={setSelectedMonthYear}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Weekly;