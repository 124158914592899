import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, FormControl, InputLabel, Select, MenuItem, Slide, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUserDialogBox = ({ open, handleCloseDialog, userData, handleInputChange, handleAddUser, companyOptions }) => {
    const theme = useTheme();

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        handleInputChange({ target: { name, value } });
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleCloseDialog}
            aria-describedby="add-user-dialog-description"
            PaperProps={{
                sx: { 
                    backgroundColor: theme.palette.background.paper, 
                    width: '600px', // Match the width of AddPackageDialogBox
                    padding: theme.spacing(2), // Add padding for consistency
                }
            }}
        >
            <DialogTitle sx={{ color: theme.palette.text.white }} variant="h2" fontWeight="bold">
                Add New User
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}> {/* Use Grid for layout */}
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label="Name"
                            fullWidth
                            value={userData.name}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            id="email"
                            name="email"
                            label="Email"
                            fullWidth
                            value={userData.email}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel id="company">Company</InputLabel>
                            <Select
                                labelId="company"
                                id="company"
                                name="company"
                                value={userData.company}
                                label="Company"
                                onChange={handleSelectChange}
                            >
                                {companyOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel id="role-label">Role</InputLabel>
                            <Select
                                labelId="role-label"
                                id="role"
                                name="role"
                                value={userData.role}
                                label="Role"
                                onChange={handleSelectChange}
                            >
                                <MenuItem value="Admin">Admin</MenuItem>
                                <MenuItem value="User">User</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: theme.spacing(2) }}> {/* Match padding */}
                <Button
                    onClick={handleCloseDialog}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.text.white,
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: theme.palette.text.white,
                        },
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleAddUser}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.text.white,
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: theme.palette.text.white,
                        },
                    }}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddUserDialogBox;