import React, { useState, useMemo } from 'react';
import { Box, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, FormControl, InputLabel, Select } from '@mui/material';
import { useGetSitesQuery, useAddDeviceMutation } from '../../services/api';
import Slide from '@mui/material/Slide';

// Forward transition component
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddDeviceDialogBox = ({ openDialog, handleCloseDialog, theme, setSnackbar, refetchDevices, selectedCompanyId }) => {

    const [ipAddress, setIpAddress] = useState('');
    const [macAddress, setMacAddress] = useState('');
    const [siteId, setSiteId] = useState('');

    const { data: sitesData } = useGetSitesQuery(selectedCompanyId, {skip: !selectedCompanyId});
    const [addDevice] = useAddDeviceMutation();

    // Filter sites and devices
    const filteredSites = useMemo(() => {
        return sitesData ? sitesData.filter(site => site.company._id === selectedCompanyId && site.enabled) : [];
    }, [sitesData, selectedCompanyId]);

    // Handle adding a device
    const handleAddDevice = async (ipAddress, macAddress, siteId) => {
        if (!ipAddress || !macAddress || !siteId) {
            setSnackbar({
                open: true,
                message: "Please fill all fields and select a site.",
                severity: "error"
            });
            return;
        }

        try {
            const response = await addDevice({ ipAddress, macAddress, siteId });
            if (response.error) {
                setSnackbar({
                    open: true,
                    message: "ERROR: No matching device for your company.",
                    severity: "error"
                });
            } else {
                setSnackbar({
                    open: true,
                    message: "Device added successfully!",
                    severity: "success"
                });
                handleCloseDialog();
                refetchDevices();
            }
        } catch (error) {
            console.error('Error adding device:', error);
            setSnackbar({
                open: true,
                message: "An error occurred while adding the device.",
                severity: "error"
            });
        }
    };

    return (
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            onClose={handleCloseDialog}
            aria-describedby="add-device-dialog-description"
            PaperProps={{ sx: { backgroundColor: theme.palette.background.paper } }}
        >
            <DialogTitle variant="h2" fontWeight="bold">
                Add Device
            </DialogTitle>
            <DialogContent sx={{ width: '500px', padding: '20px' }}>
                <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="ip"
                        label="IP Address"
                        fullWidth
                        value={ipAddress}
                        onChange={(e) => setIpAddress(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="mac"
                        label="MAC Address"
                        fullWidth
                        value={macAddress}
                        onChange={(e) => setMacAddress(e.target.value)}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="site-select-label">
                            Select Site
                        </InputLabel>
                        <Select
                            labelId="site-select-label"
                            id="site-select"
                            value={siteId}
                            label="Select Site"
                            onChange={(e) => setSiteId(e.target.value)}
                        >
                            {filteredSites.map((site) => (
                                <MenuItem key={site._id} value={site._id}>
                                    {site.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: '16px 24px' }}>
                <Button
                    onClick={handleCloseDialog}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.text.white,
                        '&:hover': {
                            backgroundColor: theme.palette.text.white,
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => handleAddDevice(ipAddress, macAddress, siteId)}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.text.white,
                        '&:hover': {
                            backgroundColor: theme.palette.text.white,
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                    }}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddDeviceDialogBox;