import React, { useState } from 'react';
import { Box, Grid, Paper, useTheme, useMediaQuery } from '@mui/material';
import { useOverviewDataQuery, useAdultnChildOverviewDataQuery, useGenderOverviewDataQuery, useGetAddonByDeviceQuery } from '../../services/api';
import { useSelector } from 'react-redux';
import OverviewHeader from './OverviewHeader';
import OverviewContent from './OverviewContent';
import useSelectedSensor from '../../hooks/useSelectedSensor';
import useSensorOptions from '../../hooks/useSensorOptions';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import OverviewFilterBox from './OverviewFilterBox';
import OverviewLabel from './OverviewLabel';

const Overview = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { role, company } = useSelector(state => state.global);

    const [tabValue, setTabValue] = useState(0);

    const { companyOptions } = useCompanyOptions();
    const isSuperOrPowerAdmin = role === 'Super Admin' || role === 'Power Admin';
    const { selectedCompany, selectedCompanyId, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const sensorOptions = useSensorOptions(isSuperOrPowerAdmin ? selectedCompanyId : company);
    const { selectedSensor, handleSensorChange, getSelectedSensorLabel } = useSelectedSensor(sensorOptions, isSuperOrPowerAdmin ? selectedCompany : company);

    const { data: addonData } = useGetAddonByDeviceQuery(selectedSensor, {
        skip: !selectedSensor,
    });

    const availableTabs = [
        { label: "TOTAL", value: 0 },
        addonData?.[0]?.adultnchild && { label: "ADULT & CHILD", value: 1 },
        addonData?.[0]?.gender && { label: "GENDER", value: 2 },
        addonData?.[0]?.mask && { label: "MASK", value: 3 },
    ].filter(Boolean);

    // Conditionally fetch data based on the selected tab and selectedSensor
    const { data: hourlyData, isLoading: hourlyLoading, error: hourlyError } = useOverviewDataQuery(
        { mac: selectedSensor },
        { skip: tabValue !== 0 || !selectedSensor }
    );

    const { data: adultChildData, isLoading: adultChildLoading, error: adultChildError } = useAdultnChildOverviewDataQuery(
        { mac: selectedSensor },
        { skip: tabValue !== 1 || !selectedSensor }
    );

    const { data: genderData, isLoading: genderLoading, error: genderError } = useGenderOverviewDataQuery(
        { mac: selectedSensor },
        { skip: tabValue !== 2 || !selectedSensor }
    );

    // Determine which data to use based on the selected tab
    const overviewData = availableTabs[tabValue]?.value === 0 ? hourlyData 
                        : availableTabs[tabValue]?.value === 1 ? adultChildData 
                        : availableTabs[tabValue]?.value === 2 ? genderData 
                        : null;

    const overviewLoading = availableTabs[tabValue]?.value === 0 ? hourlyLoading 
                            : availableTabs[tabValue]?.value === 1 ? adultChildLoading 
                            : availableTabs[tabValue]?.value === 2 ? genderLoading 
                            : null;

    const error = availableTabs[tabValue]?.value === 0 ? hourlyError 
                : availableTabs[tabValue]?.value === 1 ? adultChildError 
                : availableTabs[tabValue]?.value === 2 ? genderError 
                : null;

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box m={isSmallScreen ? "0.5rem" : "1rem 2.5rem"}>
            <Grid container spacing={2} paddingBottom={3}>
                <Grid item xs={12} md={10}>
                    <OverviewHeader isSmallScreen={isSmallScreen} />
                    <OverviewLabel
                        sensorLabel={getSelectedSensorLabel()}
                        isSmallScreen={isSmallScreen}
                        tabValue={tabValue}
                        onTabChange={handleTabChange}
                        addonData={addonData}
                    />
                    <OverviewContent
                        selectedSensor={selectedSensor}
                        overviewData={overviewData}
                        overviewLoading={overviewLoading}
                        error={error}
                        isSmallScreen={isSmallScreen}
                        sensorLabel={getSelectedSensorLabel()}
                        tabValue={tabValue}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Paper sx={{ position: 'sticky', top: 20, height: 'auto', overflow: 'auto', borderRadius: '15px', boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)' }}>
                        <OverviewFilterBox
                            sensorLabel={getSelectedSensorLabel()}
                            isSmallScreen={isSmallScreen}
                            sensorOptions={sensorOptions}
                            selectedSensor={selectedSensor}
                            handleSensorChange={handleSensorChange}
                            companyOptions={companyOptions}
                            selectedCompany={isSuperOrPowerAdmin ? selectedCompany : null}
                            handleCompanyChange={isSuperOrPowerAdmin ? handleCompanyChange : null}
                            isSuperAdmin={isSuperOrPowerAdmin}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Overview;