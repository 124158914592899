import React from 'react';
import StatBox from '../../components/StatBox';
import { useTheme, useMediaQuery } from '@mui/material';
import EdgesensorHighIcon from '@mui/icons-material/EdgesensorHigh';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const StatBoxes = ({ totalDevicesConnected, totalSites, devicesOffline, totalHumanCount }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    return (
        <>
            <StatBox
                title="Devices Connected"
                value={totalDevicesConnected.toString()}
                isSmallScreen={isSmallScreen}
                isTablet={isTablet}
                icon={<EdgesensorHighIcon sx={{ fontSize: '1.5rem', color: 'white' }} />}
                iconColor="#FA5A7D"
                backgroundColor="#FFE2E5"
            />
            <StatBox
                title="Active Sites"
                value={totalSites.toString()}
                isSmallScreen={isSmallScreen}
                isTablet={isTablet}
                icon={<LocationOnIcon sx={{ fontSize: '1.5rem', color: 'white' }} />}
                iconColor="#FF947A"
                backgroundColor="#FFF4DE"
            />
            <StatBox
                title="Devices Offline"
                value={devicesOffline.toString()}
                isSmallScreen={isSmallScreen}
                isTablet={isTablet}
                icon={<WifiOffIcon sx={{ fontSize: '1.5rem', color: 'white' }} />}
                iconColor="#3CD856"
                backgroundColor="#DCFCE7"
            />
            <StatBox
                title="Total Human Count"
                value={totalHumanCount.toString()}
                isSmallScreen={isSmallScreen}
                isTablet={isTablet}
                icon={<DirectionsRunIcon sx={{ fontSize: '1.5rem', color: 'white' }} />}
                iconColor="#BF83FF"
                backgroundColor="#F3E8FF"
            />
        </>
    );
};

export default StatBoxes;