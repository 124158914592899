import React, { useState } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { useGetAllUsersQuery, useGetDevicesQuery, useGetLicensesQuery } from "../../services/api";
import useCompanyOptions from '../../hooks/useCompanyOptions';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import CompanyStats from './CompanyStats';
import CompanyHeader from './CompanyHeader';
import CompanyContent from './CompanyContent';
import AddCompanyDialogBox from './AddCompanyDialogBox';

const Companies = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { companyOptions, refetchCompanyOptions } = useCompanyOptions();
    const { selectedCompany, selectedCompanyId, handleCompanyChange, getSelectedCompanyLabel } = useSelectedCompany({ companyOptions });

    // Fetch data with error handling
    const { data: userData = { users: [] }, error: userError } = useGetAllUsersQuery(selectedCompanyId, { skip: !selectedCompanyId });
    const { data: devicesData = [], error: devicesError } = useGetDevicesQuery(selectedCompanyId, { skip: !selectedCompanyId });
    const { data: licenseData = [], error: licenseError } = useGetLicensesQuery(selectedCompanyId, { skip: !selectedCompanyId });

    // Handle errors and provide fallback data
    const processedUserData = userError || !userData ? [] : userData.users;
    const processedDevicesData = devicesError || !devicesData ? [] : devicesData;
    const processedLicenseData = licenseError || !licenseData ? [] : licenseData;

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <CompanyHeader
                    companyOptions={companyOptions}
                    selectedCompany={selectedCompany}
                    handleCompanyChange={handleCompanyChange}
                    handleOpenDialog={handleOpenDialog}
                    getSelectedCompanyLabel={getSelectedCompanyLabel()}
                />
            </Box>
            <CompanyStats companyOptions={companyOptions} selectedCompany={selectedCompany} />
            <CompanyContent
                companyData={companyOptions}
                refetch={refetchCompanyOptions}
                selectedCompany={selectedCompany}
                selectedCompanyId={selectedCompanyId}
                userData={processedUserData}
                devicesData={processedDevicesData}
                licenseData={processedLicenseData}
                isSmallScreen={isSmallScreen}
            />
            <AddCompanyDialogBox
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                refetch={refetchCompanyOptions}
            />
        </Box>
    );
};

export default Companies;