import React, { useMemo, useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, useTheme, Box } from '@mui/material';

const UserTable = ({ userData, isSmallScreen }) => {
    const theme = useTheme();
    
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(property);
    };

    const sortedData = useMemo(() => {
        if (userData && Array.isArray(userData.users)) {
            const filteredUsers = userData.users.filter(
                (user) => user.role?.name !== 'Super Admin' && user.role?.name !== 'Power Admin'
            );

            const sortedUsers = filteredUsers.sort((a, b) => {
                if (order === 'asc') {
                    return a[orderBy] > b[orderBy] ? 1 : -1;
                } else {
                    return b[orderBy] > a[orderBy] ? 1 : -1;
                }
            });

            return sortedUsers;
        }
        return [];
    }, [userData, orderBy, order]);

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString();
    };

    return (
        <Box
            backgroundColor={theme.palette.background.paper}
            borderRadius='15px'
            sx={{
                overflowX: isSmallScreen ? 'auto' : 'hidden',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'name'}
                                direction={orderBy === 'name' ? order : 'asc'}
                                onClick={createSortHandler('name')}
                            >
                                Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'email'}
                                direction={orderBy === 'email' ? order : 'asc'}
                                onClick={createSortHandler('email')}
                            >
                                Email
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'company'}
                                direction={orderBy === 'company' ? order : 'asc'}
                                onClick={createSortHandler('company')}
                            >
                                Company
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'country'}
                                direction={orderBy === 'country' ? order : 'asc'}
                                onClick={createSortHandler('country')}
                            >
                                Country
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'phoneNumber'}
                                direction={orderBy === 'phoneNumber' ? order : 'asc'}
                                onClick={createSortHandler('phoneNumber')}
                            >
                                Phone Number
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'createdAt'}
                                direction={orderBy === 'createdAt' ? order : 'asc'}
                                onClick={createSortHandler('createdAt')}
                            >
                                Created Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'updatedAt'}
                                direction={orderBy === 'updatedAt' ? order : 'asc'}
                                onClick={createSortHandler('updatedAt')}
                            >
                                Updated Date
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData.map((user) => (
                        <TableRow key={user._id}>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.company?.name}</TableCell>
                            <TableCell>{user.country}</TableCell>
                            <TableCell>{user.phoneNumber}</TableCell>
                            <TableCell>{user.role?.name}</TableCell>
                            <TableCell>{user.active ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{formatDate(user.createdAt)}</TableCell>
                            <TableCell>{formatDate(user.updatedAt)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default UserTable;