import React from 'react';
import { Box } from '@mui/material';
import CompanyStatBox from '../../components/CompanyStatBox';
import { useMediaQuery } from 'react-responsive';
import BusinessIcon from '@mui/icons-material/Business';
import DevicesIcon from '@mui/icons-material/Devices';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DescriptionIcon from '@mui/icons-material/Description';

const CompanyStats = ({ companyOptions, selectedCompany }) => {
    const selectedCompanyData = companyOptions.find(option => option.name === selectedCompany) || {};

    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    return (
        <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="160px"
            gap="20px"
            marginTop="1rem"
        >
            <CompanyStatBox
                title="DEVICES CONNECTED"
                value={selectedCompanyData.devicesConnected || 0}
                isSmallScreen={isSmallScreen}
                icon={<DevicesIcon sx={{ fontSize: '1.5rem', color: 'white' }} />}
                iconColor="#FF947A"
                backgroundColor="#FFF4DE"
            />
            <CompanyStatBox
                title="ACTIVE SITES"
                value={selectedCompanyData.sitesConnected || 0}
                isSmallScreen={isSmallScreen}
                icon={<LocationOnIcon sx={{ fontSize: '1.5rem', color: 'white' }} />}
                iconColor="#3CD856"
                backgroundColor="#DCFCE7"
            />
            <CompanyStatBox
                title="ACTIVE LOGICS"
                value={selectedCompanyData.logicsConnected || 0}
                isSmallScreen={isSmallScreen}
                icon={<DescriptionIcon sx={{ fontSize: '1.5rem', color: 'white' }} />}
                iconColor="#BF83FF"
                backgroundColor="#F3E8FF"
            />
            <CompanyStatBox
                title="TOTAL APP USERS"
                value={selectedCompanyData.usersConnected || 0}
                isSmallScreen={isSmallScreen}
                icon={<BusinessIcon sx={{ fontSize: '1.5rem', color: 'white' }} />}
                iconColor="#FA5A7D"
                backgroundColor="#FFE2E5"
            />
        </Box>
    );
};

export default CompanyStats;