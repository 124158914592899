import React from 'react';
import AdminStatBox from '../../components/StatBox';
import { useTheme, useMediaQuery } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import DevicesIcon from '@mui/icons-material/Devices';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DescriptionIcon from '@mui/icons-material/Description';

const AdminStatBoxes = ({ counts }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    return (
        <>
            <AdminStatBox
                title="COMPANIES"
                value={counts ? counts.numberOfCompanies : 0}
                isSmallScreen={isSmallScreen}
                isTablet={isTablet}
                icon={<BusinessIcon sx={{ fontSize: '1.5rem', color: 'white' }} />}
                iconColor="#FA5A7D"
                backgroundColor="#FFE2E5"
            />
            <AdminStatBox
                title="DEVICES"
                value={counts ? counts.numberOfDevices : 0}
                isSmallScreen={isSmallScreen}
                isTablet={isTablet}
                icon={<DevicesIcon sx={{ fontSize: '1.5rem', color: 'white' }} />}
                iconColor="#FF947A"
                backgroundColor="#FFF4DE"
            />
            <AdminStatBox
                title="SITES"
                value={counts ? counts.numberOfSites : 0}
                isSmallScreen={isSmallScreen}
                isTablet={isTablet}
                icon={<LocationOnIcon sx={{ fontSize: '1.5rem', color: 'white' }} />}
                iconColor="#3CD856"
                backgroundColor="#DCFCE7"
            />
            <AdminStatBox
                title="LOGICS"
                value={counts ? counts.numberOfLogicDocuments : 0}
                isSmallScreen={isSmallScreen}
                isTablet={isTablet}
                icon={<DescriptionIcon sx={{ fontSize: '1.5rem', color: 'white' }} />}
                iconColor="#BF83FF"
                backgroundColor="#F3E8FF"
            />
        </>
    );
};

export default AdminStatBoxes;