import React, { useState } from 'react';
import { Box, Typography, Divider, Switch, FormControlLabel, useTheme } from '@mui/material';
import { useUpdateAddonMutation } from '../../services/api';

const AddonBox = ({ _id, title, status, adultnchild: initialAdultnchild, gender: initialGender, mask: initialMask }) => {
    const theme = useTheme();

    const [updateAddon] = useUpdateAddonMutation();
    const [adultnchild, setAdultnchild] = useState(initialAdultnchild);
    const [gender, setGender] = useState(initialGender);
    const [mask, setMask] = useState(initialMask);

    const handleSwitchChange = async (field, value) => {
        try {
            await updateAddon({ addonId: _id, data: { [field]: value } });
            if (field === 'adultnchild') setAdultnchild(value);
            if (field === 'gender') setGender(value);
            if (field === 'mask') setMask(value);
        } catch (error) {
            console.error('Failed to update addon:', error);
        }
    };

    const dotColor = status.includes('OFFLINE') ? 'red' : 'green';

    const boxShadow = status.includes('OFFLINE') 
        ? `5px 4px 15px rgba(255, 0, 0, 0.9)` 
        : `5px 4px 15px rgba(0, 255, 0, 0.6)`;

    return (
        <Box
            gridColumn="span 2"
            gridRow="span 1"
            position="relative"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p="2rem 1rem"
            flex="1 1 100%"
            backgroundColor={theme.palette.background.paper}
            borderRadius="1.5rem"
            height="325px"
            width="275px"
            boxShadow={boxShadow}
        >
            <Typography 
                variant="h3" 
                mt="20px" 
                fontWeight="600" 
                color={theme.palette.text.white}
                textAlign="center" 
                width="100%"
            >
                {title.replace(/\s*\(.*?\)\s*/g, '')}
            </Typography>

            <Divider sx={{ margin: '1rem 0', width: '100%', borderTop: '2px solid #000' }} />

            {/* Toggles with text labels */}
            <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <FormControlLabel
                        control={<Switch checked={adultnchild} onChange={(e) => handleSwitchChange('adultnchild', e.target.checked)} />}
                        label={<Typography variant="body1" color={theme.palette.text.white}>Adult & Child</Typography>}
                        sx={{ marginBottom: '0.5rem' }}
                    />
                    <FormControlLabel
                        control={<Switch checked={gender} onChange={(e) => handleSwitchChange('gender', e.target.checked)} />}
                        label={<Typography variant="body1" color={theme.palette.text.white}>Gender</Typography>}
                        sx={{ marginBottom: '0.5rem' }}
                    />
                    <FormControlLabel
                        control={<Switch checked={mask} onChange={(e) => handleSwitchChange('mask', e.target.checked)} />}
                        label={<Typography variant="body1" color={theme.palette.text.white}>Face Mask</Typography>}
                    />
                </Box>
            </Box>

            <Divider sx={{ margin: '1rem 0', width: '100%', borderTop: '2px solid #000' }} />

            <Box display="flex" alignItems="center" mb="5px">
                <Box width="12px" height="12px" borderRadius="50%" mr="5px" bgcolor={dotColor} />
                <Typography variant="h5" fontWeight="600">
                    {status}
                </Typography>
            </Box>
        </Box>
    );
};

export default AddonBox;