import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, useTheme, Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import ErrorSnackbar from '../../components/ErrorSnackbar';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddPackageDialogBox = ({ open, handleCloseDialog, packageData, handleInputChange, handleAddPackage, refetchLicensesData, refetchPackageData }) => {
    const theme = useTheme();
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

    const handleAddPackageClick = async () => {
        try {
            await handleAddPackage();
            setSnackbar({
                open: true,
                message: "Package added successfully!",
                severity: "success"
            });
            handleCloseDialog();
            refetchLicensesData();
            refetchPackageData();
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Error adding package. Please try again.",
                severity: "error"
            });
            console.error('Error adding package:', error);
        }
    };

    const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleCloseDialog}
                aria-describedby="add-package-dialog-description"
                PaperProps={{
                    sx: { 
                        backgroundColor: theme.palette.background.paper, 
                        width: '600px',
                        padding: theme.spacing(2),
                    }
                }}
            >
                <DialogTitle sx={{ color: theme.palette.text.white }} variant="h2" fontWeight="bold">
                    Add New Package
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                name="name"
                                label="Package Name"
                                fullWidth
                                value={packageData.name}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                id="description"
                                name="description"
                                label="Description"
                                fullWidth
                                value={packageData.description}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                id="durationInDays"
                                name="durationInDays"
                                label="Duration (Days)"
                                fullWidth
                                value={packageData.durationInDays}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ padding: theme.spacing(2) }}>
                    <Button
                        onClick={handleCloseDialog}
                        variant="contained"
                        sx={{
                            color: theme.palette.text.black,
                            backgroundColor: theme.palette.text.white,
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: theme.palette.text.white,
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAddPackageClick}
                        variant="contained"
                        sx={{
                            color: theme.palette.text.black,
                            backgroundColor: theme.palette.text.white,
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: theme.palette.text.white,
                            },
                        }}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            <ErrorSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={handleSnackbarClose}
            />
        </>
    );
};

export default AddPackageDialogBox;