import React, { useState } from 'react';
import { LightModeOutlined, DarkModeOutlined, Menu as MenuIcon, SettingsOutlined, ArrowDropDownOutlined, AccountCircle } from '@mui/icons-material';
import FlexBetween from './FlexBetween';
import { useSelector, useDispatch } from "react-redux";
import { setMode, logout, setId, setUsername, setCompany, setRole, setSelectedDevice, setPermissions, setAssignedCompanies } from '../redux/slices/globalSlice';
import { AppBar, IconButton, Box, Button, Typography, Menu, MenuItem, Toolbar, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../services/api';

const Navbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const [logoutMutation] = useLogoutMutation();
    const username = useSelector(state => state.global.username);

    const handleLogout = async () => {
        try {
            const response = await logoutMutation();
            if (response.data.message === "Successfully Logged Out") {
                dispatch(logout());
                dispatch(setId(null));
                dispatch(setUsername(null));
                dispatch(setCompany(null));
                dispatch(setRole(null));
                dispatch(setSelectedDevice(null));
                dispatch(setPermissions(null));
                dispatch(setAssignedCompanies(null));
                navigate("/login");
            } else {
                console.error("Logout failed:", response);
            }
        } catch (error) {
            console.error("An error occurred while logging out:", error);
        }
    };

    const handleSettings = () => {
        navigate('/settings');
    };

    return (
        <AppBar sx={{ position: "static", background: "none", boxShadow: "none" }}>
            <Toolbar sx={{ justifyContent: "space-between" }}>
                <FlexBetween>
                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)} sx={{ "&:focus, &:active": { outline: "none" } }}>
                        <MenuIcon />
                    </IconButton>
                </FlexBetween>
                <FlexBetween gap="1.5rem">
                    <IconButton onClick={() => dispatch(setMode())} sx={{ "&:focus, &:active": { outline: "none" } }}>
                        {theme.palette.mode === "dark" ? (
                            <DarkModeOutlined sx={{ fontSize: "25px" }} />
                        ) : (
                            <LightModeOutlined sx={{ fontSize: "25px" }} />
                        )}
                    </IconButton>
                    <IconButton onClick={handleSettings} sx={{ "&:focus, &:active": { outline: "none" } }}>
                        <SettingsOutlined sx={{ fontSize: "25px" }} />
                    </IconButton>
                    <FlexBetween>
                        <Button
                            onClick={handleClick}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                textTransform: "none",
                                gap: "1rem",
                                "&:focus, &:active": { outline: "none" },
                            }}
                        >
                            <AccountCircle sx={{ fontSize: "32px", color: theme.palette.text.white }} />
                            <Box textAlign="left">
                                <Typography
                                    fontWeight="bold"
                                    fontSize="0.85rem"
                                    color={theme.palette.text.white}
                                >
                                    {username}
                                </Typography>
                            </Box>
                            <ArrowDropDownOutlined sx={{ color: theme.palette.text.white }}/>
                        </Button>
                        <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                        </Menu>
                    </FlexBetween>
                </FlexBetween>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;
