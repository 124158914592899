import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import DeviceBox from './DeviceBox';
import NoDataAvailableMessage from '../../components/NoDataAvailable';

const DeviceList = ({ filteredDevices = [] }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    return filteredDevices.length === 0 ? (
        <Box
            height="55vh"
        >
            <NoDataAvailableMessage />
        </Box>
    ) : (
        <Box
            marginTop="20px"
            display="grid"
            gridTemplateColumns={isMobile ? "1fr" : isTablet ? "repeat(2, 1fr)" : "repeat(4, 1fr)"}
            gap="20px"
            rowGap="40px"
            justifyItems="center"
            alignItems="start"
        >
            {filteredDevices.map(device => (
                <DeviceBox
                    key={device._id}
                    title={device.name}
                    deviceType={`Type: ${device.type}`}
                    macAddress={`MAC: ${device.mac}`}
                    firmware={`Firmware: ${device.firmware}`}
                    ip={`IP: ${device.ip}`}
                    location={device.site.name}
                    status={`Status: ${device.status}`}
                />
            ))}
        </Box>
    );
};

export default DeviceList;