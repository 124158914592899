import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Chip, Box, OutlinedInput, ListItemText, Checkbox, useTheme, Grid } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 'auto',
            overflowY: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
    },
};

const EditPermissionsDialogBox = ({ open, onClose, selectedPermissions, selectedCompanies, handlePermissionChange, handleCompanyChange, permissionsData, handleSave, showSnackbar, companyOptions }) => {
    const [selectedCompaniesState, setSelectedCompaniesState] = useState(selectedCompanies || []);
    const theme = useTheme();

    useEffect(() => {
        setSelectedCompaniesState(selectedCompanies);
    }, [selectedCompanies]);

    const handleTogglePermission = (permissionId) => {
        const currentIndex = selectedPermissions.indexOf(permissionId);
        const newSelected = [...selectedPermissions];

        if (currentIndex === -1) {
            newSelected.push(permissionId);
        } else {
            newSelected.splice(currentIndex, 1);
        }

        handlePermissionChange({ target: { value: newSelected } });
    };

    const handleToggleCompany = (companyId) => {
        const currentIndex = selectedCompaniesState.indexOf(companyId);
        const newSelected = [...selectedCompaniesState];

        if (currentIndex === -1) {
            newSelected.push(companyId);
        } else {
            newSelected.splice(currentIndex, 1);
        }

        setSelectedCompaniesState(newSelected);
    };

    const isPermissionChecked = (permissionId) => {
        return selectedPermissions.includes(permissionId);
    };

    const isCompanyChecked = (companyId) => {
        return selectedCompaniesState.includes(companyId);
    };

    const renderValue = (selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {permissionsData?.filter(permission => selected.includes(permission._id)).map(permission => (
                <Chip key={permission._id} label={permission.name} />
            ))}
        </Box>
    );

    const renderCompanyValue = (selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {companyOptions?.filter(company => selected.includes(company.id)).map(company => (
                <Chip key={company.id} label={company.name} />
            ))}
        </Box>
    );

    const handleSaveClick = async () => {
        try {
            await handleSave(selectedCompaniesState);
            showSnackbar('Permissions and companies updated successfully', 'success');
        } catch (error) {
            showSnackbar('Failed to update permissions and companies', 'error');
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                sx: { 
                    backgroundColor: theme.palette.background.paper, 
                    width: '600px', // Match the width of other dialogs
                    padding: theme.spacing(2), // Add padding for consistency
                }
            }}
        >
            <DialogTitle sx={{ color: theme.palette.text.white }} variant="h2" fontWeight="bold">
                Edit Permissions and Companies
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}> {/* Use Grid for layout */}
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel id="permissions-label">Permissions</InputLabel>
                            <Select
                                labelId="permissions-label"
                                id="permissions-select"
                                multiple
                                value={selectedPermissions}
                                onChange={handlePermissionChange}
                                input={<OutlinedInput label="Permissions" />}
                                renderValue={renderValue}
                                MenuProps={MenuProps}
                            >
                                {permissionsData?.map((permission) => (
                                    <MenuItem
                                        key={permission._id}
                                        value={permission._id}
                                        onClick={() => handleTogglePermission(permission._id)}
                                    >
                                        <Checkbox
                                            checked={isPermissionChecked(permission._id)}
                                            edge="start"
                                        />
                                        <ListItemText primary={permission.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel id="companies-label">Companies</InputLabel>
                            <Select
                                labelId="companies-label"
                                id="companies-select"
                                multiple
                                value={selectedCompaniesState}
                                onChange={(e) => handleCompanyChange(e)}
                                input={<OutlinedInput label="Companies" />}
                                renderValue={renderCompanyValue}
                                MenuProps={MenuProps}
                            >
                                {companyOptions?.map((company) => (
                                    <MenuItem
                                        key={company.id}
                                        value={company.id}
                                        onClick={() => handleToggleCompany(company.id)}
                                    >
                                        <Checkbox
                                            checked={isCompanyChecked(company.id)}
                                            edge="start"
                                        />
                                        <ListItemText primary={company.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: theme.spacing(2) }}> {/* Match padding */}
                <Button
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.text.white,
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: theme.palette.text.white,
                        },
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSaveClick}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.text.white,
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: theme.palette.text.white,
                        },
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditPermissionsDialogBox;