import React from 'react';
import { Box, useTheme, Button } from '@mui/material';
import Header from '../../components/Header';
import { AddBoxOutlined } from '@mui/icons-material';

const AdminManagementHeader = ({ handleOpenDialog, isSmallScreen }) => {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" gap="1rem">
            {isSmallScreen ? (
                <>
                    <Box 
                        display="flex" 
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="0.5rem"
                        sx={{
                            boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Box flexGrow={1}>
                            <Header title="ADMIN MANAGEMENT" subtitle="View and manage all your admins" />
                        </Box>
                    </Box>
                    <Box 
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="1rem"
                        gap="1rem"
                        sx={{
                            boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Button
                            onClick={handleOpenDialog}
                            sx={{
                                color: theme.palette.text.black,
                                backgroundColor: theme.palette.text.white,
                                minWidth: '150px',
                                height: '50px',
                                '&:hover': {
                                    backgroundColor: theme.palette.text.white,
                                },
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                        >
                            <AddBoxOutlined sx={{ marginRight: "8px" }} />
                            Create Power Admin
                        </Button>
                    </Box>
                </>
            ) : (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    backgroundColor={theme.palette.background.paper}
                    borderRadius='15px'
                    padding="1rem"
                    sx={{
                        boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Header title="ADMIN MANAGEMENT" subtitle="View and manage all your admins" />
                    <Box display="flex" alignItems="center" gap="1rem">
                        <Button
                            onClick={handleOpenDialog}
                            sx={{
                                color: theme.palette.text.black,
                                backgroundColor: theme.palette.text.white,
                                minWidth: '150px',
                                height: '50px',
                                '&:hover': {
                                    backgroundColor: theme.palette.text.white,
                                },
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                        >
                            <AddBoxOutlined sx={{ marginRight: "8px" }} />
                            Create Power Admin
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default AdminManagementHeader;
