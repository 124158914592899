import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, useTheme, Grid } from '@mui/material';
import ErrorSnackbar from '../../components/ErrorSnackbar';

const RenewLicenseDialogBox = ({ open, onClose, packagesData, selectedPackage, handlePackageChange, handleSave }) => {
    const theme = useTheme();
    const [selectedPackageState, setSelectedPackageState] = useState(selectedPackage);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedPackageState(newValue);
        handlePackageChange(event);
    };

    const handleSaveClick = async () => {
        try {
            await handleSave(selectedPackageState);
            setSnackbar({
                open: true,
                message: 'License updated successfully',
                severity: 'success'
            });
            onClose();
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Failed to update package',
                severity: 'error'
            });
            console.error('Error updating package:', error);
        }
    };

    const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    sx: { 
                        backgroundColor: theme.palette.background.paper, 
                        width: '600px',
                        padding: theme.spacing(2),
                    }
                }}
            >
                <DialogTitle variant="h2" fontWeight="bold" sx={{ color: theme.palette.text.white }}>
                    Edit Package
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" margin="normal">
                                <InputLabel id="package-label">Package</InputLabel>
                                <Select
                                    labelId="package-label"
                                    id="package-select"
                                    value={selectedPackageState || ''}
                                    onChange={handleChange}
                                    label="Package"
                                >
                                    {packagesData?.map((pkg) => (
                                        <MenuItem key={pkg._id} value={pkg._id}>
                                            {pkg.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ padding: theme.spacing(2) }}>
                    <Button
                        onClick={onClose}
                        variant="contained"
                        sx={{
                            color: theme.palette.text.black,
                            backgroundColor: theme.palette.text.white,
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: theme.palette.text.white,
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveClick}
                        variant="contained"
                        sx={{
                            color: theme.palette.text.black,
                            backgroundColor: theme.palette.text.white,
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: theme.palette.text.white,
                            },
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <ErrorSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                handleClose={handleSnackbarClose}
            />
        </>
    );
};

export default RenewLicenseDialogBox;