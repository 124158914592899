import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

const DowntimeLogLabel = ({ sensorLabel, isSmallScreen }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={isMobile ? '0rem' : '1rem'}
            p={isMobile ? '0.5rem' : '1rem'}
            backgroundColor={theme.palette.background.paper}
            borderRadius="15px"
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography
                variant={isMobile ? "h3" : "h2"}
                component="div"
                sx={{
                    color: theme.palette.text.white,
                    fontWeight: "bold",
                    textAlign: "left",
                    ml: isMobile ? '0.5rem' : '0',
                }}
            >
                {sensorLabel}
            </Typography>
        </Box>
    );
};

export default DowntimeLogLabel;