import React from 'react';
import { Box, Typography } from '@mui/material';

const CompanyStatBox = ({ title, value, isSmallScreen, icon, iconColor, backgroundColor }) => {
    return (
        <Box
            gridColumn={isSmallScreen ? "span 12" : "span 3"}
            gridRow="span 1"
            borderRadius="0.55rem"
            position="relative"
            overflow="hidden"
            backgroundColor={backgroundColor}
            padding="1rem"
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    width: '2.5rem',
                    height: '2.5rem',
                    borderRadius: '50%',
                    backgroundColor: iconColor,
                    mb: '1rem',
                }}
            >
                {icon}
            </Box>

            {/* Value */}
            <Typography variant="h2" fontWeight="600" sx={{ mt: '0.5rem', ml: '0.5rem', color: 'black' }}>
                {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Typography>

            {/* Title */}
            <Typography variant="h6" sx={{ mt: '0.5rem', mb: '1rem', ml: '0.5rem', color: 'black' }}>
                {title}
            </Typography>
        </Box>
    );
};

export default CompanyStatBox;