import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { useGetTotalCompaniesQuery } from '../../services/api';
import HomepageHeader from './HomepageHeader';
import AdminStatBoxes from './AdminStatBoxs';
import MapComponent from './MapComponent';
import GrowthChart from './GrowthChart';

const Homepage = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { data: counts } = useGetTotalCompaniesQuery();

    const yearsOfCompanyCreation = counts?.yearsOfCompanyCreation;

    // JSX
    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <HomepageHeader />
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 2fr)"
                gridAutoRows="160px"
                gap="20px"
            >
                <AdminStatBoxes counts={counts} />
                <MapComponent />
                <GrowthChart yearsOfCompanyCreation={yearsOfCompanyCreation} />
            </Box>
        </Box>
    );
}

export default Homepage;
