import React from 'react';
import { Box, useTheme, Button, Typography, useMediaQuery } from '@mui/material';
import Header from '../../components/Header';
import CompanySelector from '../../components/CompanySelector';
import { AddBoxOutlined } from '@mui/icons-material';

const CompanyHeader = ({ companyOptions, selectedCompany, handleCompanyChange, handleOpenDialog, getSelectedCompanyLabel }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box width="100%">
            {isSmallScreen ? (
                <>
                    <Box 
                        display="flex" 
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="0.5rem"
                        marginBottom="1rem"
                        sx={{
                            boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Header title="COMPANIES" subtitle="View all registered company details" />
                    </Box>
                    <Box 
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="1rem"
                        gap="1rem"
                        sx={{
                            boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <CompanySelector
                            companyOptions={companyOptions}
                            selectedCompany={selectedCompany}
                            handleCompanyChange={handleCompanyChange}
                        />
                        <Button
                            onClick={handleOpenDialog}
                            sx={{
                                color: theme.palette.text.black,
                                backgroundColor: theme.palette.text.white,
                                minWidth: '150px',
                                height: '50px',
                                '&:hover': {
                                    backgroundColor: theme.palette.text.white,
                                },
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                        >
                            <AddBoxOutlined sx={{ marginRight: "10px" }} />
                            Add Company
                        </Button>
                    </Box>
                    <Box
                        marginTop="1rem"
                        backgroundColor={theme.palette.background.paper}
                        borderRadius='15px'
                        p='0.5rem'
                        sx={{
                            boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Typography
                            variant={isSmallScreen ? "h3" : "h2"}
                            component="div"
                            sx={{
                                color: theme.palette.text.white,
                                fontWeight: "bold",
                                textAlign: "left",
                                ml: isSmallScreen ? '0.5rem' : '0',
                            }}
                        >
                            {getSelectedCompanyLabel}
                        </Typography>
                    </Box>
                </>
            ) : (
                <>
                    <Box 
                        display="flex" 
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="1rem"
                        sx={{
                            boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Header title="COMPANIES" subtitle="View all registered company details" />
                        <Box display="flex" alignItems="center" gap="1rem">
                            <CompanySelector
                                companyOptions={companyOptions}
                                selectedCompany={selectedCompany}
                                handleCompanyChange={handleCompanyChange}
                            />
                            <Button
                                onClick={handleOpenDialog}
                                sx={{
                                    color: theme.palette.text.black,
                                    backgroundColor: theme.palette.text.white,
                                    minWidth: '150px',
                                    height: '50px',
                                    '&:hover': {
                                        backgroundColor: theme.palette.text.white,
                                    },
                                    '&:focus': {
                                        outline: 'none',
                                    },
                                }}
                            >
                                <AddBoxOutlined sx={{ marginRight: "10px" }} />
                                Add Company
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt="1rem"
                        p={isSmallScreen ? '0' : '1rem'}
                        backgroundColor={theme.palette.background.paper}
                        borderRadius="15px"
                        sx={{
                            boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Typography
                            variant={isSmallScreen ? "h3" : "h2"}
                            component="div"
                            sx={{
                                color: theme.palette.text.white,
                                fontWeight: "bold",
                                textAlign: "left",
                                ml: isSmallScreen ? '0.5rem' : '0',
                            }}
                        >
                            {getSelectedCompanyLabel}
                        </Typography>
                    </Box>
                </>    
            )}
        </Box>
    );
};

export default CompanyHeader;
