import React, { useState } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import LicensesHeader from './LicensesHeader';
import { useGetLicensesQuery, useGetPackagesQuery, useAddPackageMutation } from '../../services/api';
import LicenseTable from './LicensesTable';
import AddPackageDialogBox from './AddPackageDialogBox';

const Licenses = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { companyOptions } = useCompanyOptions();
    const { selectedCompany, selectedCompanyId, handleCompanyChange, getSelectedCompanyLabel } = useSelectedCompany({ companyOptions });
    
    const { data: licenseData = [], error: licenseError, refetch: refetchLicensesData } = useGetLicensesQuery(selectedCompanyId, { skip: !selectedCompanyId });
    const processedLicenseData = licenseError || !licenseData ? [] : licenseData;
    const { data: packagesData, refetch: refetchPackageData } = useGetPackagesQuery();
    const [addPackage] = useAddPackageMutation();
    
    const [packageData, setPackageData] = useState({ name: '', description: '', durationInDays: '' });
    const [openPackageDialog, setOpenPackageDialog] = useState(false);

    const handleOpenPackageDialog = () => setOpenPackageDialog(true);
    const handleClosePackageDialog = () => setOpenPackageDialog(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPackageData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleAddPackage = async () => {
        try {
            await addPackage(packageData).unwrap();
            setOpenPackageDialog(false);
            refetchLicensesData();
            refetchPackageData();
        } catch (error) {
            console.error('Error adding package:', error);
        }
    };

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <LicensesHeader 
                selectedCompany={selectedCompany}
                handleCompanyChange={handleCompanyChange}
                companyOptions={companyOptions}
                handleOpenPackageDialog={handleOpenPackageDialog}
                getSelectedCompanyLabel={getSelectedCompanyLabel()}
            />
            <LicenseTable licencesData={processedLicenseData} packagesData={packagesData} refetchLicensesData={refetchLicensesData} isSmallScreen />
            <AddPackageDialogBox
                open={openPackageDialog}
                handleCloseDialog={handleClosePackageDialog}
                packageData={packageData}
                handleInputChange={handleInputChange}
                handleAddPackage={handleAddPackage}
                refetchLicensesData={refetchLicensesData}
                refetchPackageData={refetchPackageData}
            />
        </Box>
    );
};

export default Licenses;