import React, { useState, useMemo } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, useTheme, Box } from '@mui/material';

const AllLogicsTable = ({ logicsData = [], selectedCompany, isSmallScreen }) => {
    const theme = useTheme();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(property);
    };

    const filteredLogicsData = useMemo(() => {
        if (!selectedCompany) {
            return logicsData;
        } else {
            return logicsData.filter(logic => logic.company === selectedCompany);
        }
    }, [logicsData, selectedCompany]);

    const sortedData = useMemo(() => {
        if (filteredLogicsData) {
            const sortedLogics = [...filteredLogicsData].sort((a, b) => {
                if (order === 'asc') {
                    return a[orderBy] > b[orderBy] ? 1 : -1;
                } else {
                    return b[orderBy] > a[orderBy] ? 1 : -1;
                }
            });
            return sortedLogics;
        }
        return [];
    }, [filteredLogicsData, orderBy, order]);

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString();
    };

    return (
        <Box
            backgroundColor={theme.palette.background.paper}
            borderRadius='15px'
            sx={{
                overflowX: isSmallScreen ? 'auto' : 'hidden',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                mt: '1rem'
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'logicID'}
                                direction={orderBy === 'logicID' ? order : 'asc'}
                                onClick={createSortHandler('logicID')}
                            >
                                Logic ID
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'logicName'}
                                direction={orderBy === 'logicName' ? order : 'asc'}
                                onClick={createSortHandler('logicName')}
                            >
                                Logic Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'sensorName'}
                                direction={orderBy === 'sensorName' ? order : 'asc'}
                                onClick={createSortHandler('sensorName')}
                            >
                                Sensor Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'mac'}
                                direction={orderBy === 'mac' ? order : 'asc'}
                                onClick={createSortHandler('mac')}
                            >
                                MAC
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'company'}
                                direction={orderBy === 'company' ? order : 'asc'}
                                onClick={createSortHandler('company')}
                            >
                                Company
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'lineType'}
                                direction={orderBy === 'lineType' ? order : 'asc'}
                                onClick={createSortHandler('lineType')}
                            >
                                Line Type
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'lineName'}
                                direction={orderBy === 'lineName' ? order : 'asc'}
                                onClick={createSortHandler('lineName')}
                            >
                                Line Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'createdAt'}
                                direction={orderBy === 'createdAt' ? order : 'asc'}
                                onClick={createSortHandler('createdAt')}
                            >
                                Created Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'updatedAt'}
                                direction={orderBy === 'updatedAt' ? order : 'asc'}
                                onClick={createSortHandler('updatedAt')}
                            >
                                Updated Date
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData.map(logic => (
                        <TableRow key={logic._id}>
                            <TableCell>{logic.logicId}</TableCell>
                            <TableCell>{logic.logicName}</TableCell>
                            <TableCell>{logic.sensorName}</TableCell>
                            <TableCell>{logic.mac}</TableCell>
                            <TableCell>{logic.company}</TableCell>
                            <TableCell>{logic.geometries[0].type}</TableCell>
                            <TableCell>{logic.geometries[0].name}</TableCell>
                            <TableCell>{formatDate(logic.createdAt)}</TableCell>
                            <TableCell>{formatDate(logic.updatedAt)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    )
}

export default AllLogicsTable