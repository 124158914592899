import React, { useState } from 'react';
import { Box, Typography, useTheme, Divider, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRemoveDeviceMutation, useGetDevicesQuery } from "../../services/api";
import Slide from '@mui/material/Slide';
import { useSelector } from 'react-redux';
import ErrorSnackbar from '../../components/ErrorSnackbar';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeviceBox = ({ title, deviceType, macAddress, ip, location, status }) => {
    const theme = useTheme();

    const { role, company } = useSelector(state => state.global);

    const { data: devicesData, refetch: refetchDevices } = useGetDevicesQuery(company);
    const [removeDevice, { isLoading }] = useRemoveDeviceMutation();

    const [openDialog, setOpenDialog] = useState(false);
    const [macToRemove, setMacToRemove] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const dotColor = status.includes('OFFLINE') ? 'red' : 'green';

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmRemoveDevice = async () => {
        try {
            if (!macToRemove) {
                setSnackbarOpen(true);
                setSnackbarMessage('Please enter the MAC Address');
                setSnackbarSeverity('error');
                return;
            }

            const belongsToCompany = devicesData.some(device => device.mac === macToRemove && device.company._id === company);

            if (!belongsToCompany) {
                setSnackbarOpen(true);
                setSnackbarMessage('Device not found');
                setSnackbarSeverity('error');
                return;
            }

            await removeDevice({ macAddress: macToRemove });
            setOpenDialog(false);
            refetchDevices();
        } catch (error) {
            console.error('Error removing device:', error);
            setSnackbarOpen(true);
            setSnackbarMessage('Error removing device. Please try again later.');
            setSnackbarSeverity('error');
        }
    };

    const boxShadow = status.includes('OFFLINE') 
        ? `5px 4px 15px rgba(255, 0, 0, 0.9)` 
        : `5px 4px 15px rgba(0, 255, 0, 0.6)`;

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p="2rem 1rem"
            backgroundColor={theme.palette.background.paper}
            borderRadius="1.5rem"
            height="325px"
            width="90%"
            boxShadow={boxShadow}
            sx={{
                transition: theme.transitions.create(['background-color']),
                '&:hover': {
                    backgroundColor: theme.palette.background.default,
                },
                position: 'relative',
            }}
        >
            {role === 'Admin' && (
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        color: theme.palette.secondary[200],
                        '&:focus': { outline: 'none', },
                    }}
                    onClick={handleOpenDialog}
                >
                    <CloseIcon />
                </IconButton>
            )}

            <Typography variant="h3" mt="20px" fontWeight="600" color={theme.palette.text.white}>
                {title.replace(/\s*\(.*?\)\s*/g, '')}
            </Typography>

            <Divider sx={{ margin: '1rem 0', width: '100%', borderTop: `2px solid ${theme.palette.text.white}` }} />

            <Typography variant="h6" mb="5px" >
                {deviceType}
            </Typography>

            <Typography variant="h6" mb="5px" >
                {macAddress}
            </Typography>

            <Typography variant="h6" mb="5px" >
                {ip}
            </Typography>

            <Typography variant="h6" >
                Location: {location}
            </Typography>

            <Divider sx={{ margin: '1rem 0', width: '100%', borderTop: `2px solid ${theme.palette.text.white}` }} />

            <Box display="flex" alignItems="center" mb="5px">
                <Box width="12px" height="12px" borderRadius="50%" mr="5px" bgcolor={dotColor} />
                <Typography variant="h5" fontWeight="600">
                    {status}
                </Typography>
            </Box>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                    backgroundColor: theme.palette.background.paper,
                    width: '600px',
                    padding: theme.spacing(2),
                    },
                }}
                >
                <DialogTitle variant="h2" fontWeight="bold" sx={{ color: theme.palette.text.white }}>
                    Remove Device
                </DialogTitle>
                <DialogContent sx={{ padding: '20px' }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{ color: theme.palette.text.white }}>
                        Are you sure you want to remove this device?
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        margin="dense"
                        id="macToRemove"
                        label="MAC Address"
                        fullWidth
                        value={macToRemove}
                        onChange={(e) => setMacToRemove(e.target.value)}
                        />
                    </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ padding: '16px 24px' }}>
                    <Button
                    onClick={handleCloseDialog}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.text.white,
                        fontWeight: 'bold',
                        '&:hover': {
                        backgroundColor: theme.palette.text.white,
                        },
                    }}
                    >
                    Cancel
                    </Button>
                    <Button
                    onClick={handleConfirmRemoveDevice}
                    disabled={isLoading}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.text.white,
                        fontWeight: 'bold',
                        '&:hover': {
                        backgroundColor: theme.palette.text.white,
                        },
                    }}
                    >
                    Remove
                    </Button>
                </DialogActions>
                </Dialog>

            <ErrorSnackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} message={snackbarMessage} severity={snackbarSeverity} />
        </Box>
    );
}

export default DeviceBox;