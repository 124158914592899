import React, { useState, useMemo } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { useGetDevicesQuery } from "../../services/api";
import useSelectedCompany from '../../hooks/useSelectedCompany';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import AllDevicesHeader from './AllDevicesHeader';
import AllDevicesTable from './AllDevicesTable';
import AddDeviceDialogBox from './AddDeviceDialogBox';
import ErrorSnackbar from '../../components/ErrorSnackbar';

const AllDevices = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { companyOptions } = useCompanyOptions();
    const { selectedCompany, selectedCompanyId, handleCompanyChange } = useSelectedCompany({ companyOptions });

    const { data: devicesData, refetch } = useGetDevicesQuery(selectedCompanyId, {skip: !selectedCompanyId});

    const [openDialog, setOpenDialog] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

    const filteredDevices = useMemo(() => {
        return devicesData && devicesData.filter(device => device.addedToDashboard);
    }, [devicesData]);

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <AllDevicesHeader 
                selectedCompany={selectedCompany}
                handleCompanyChange={handleCompanyChange}
                companyOptions={companyOptions}
                handleOpenDialog={handleOpenDialog}
                isSmallScreen={isSmallScreen}
            />
            <AllDevicesTable devicesData={filteredDevices} selectedCompanyId={selectedCompanyId} isSmallScreen={isSmallScreen} />
            <AddDeviceDialogBox
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                theme={theme}
                refetchDevices={refetch}
                setSnackbar={setSnackbar}
                selectedCompanyId={selectedCompanyId}
            />
            <ErrorSnackbar
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Box>
    );
}

export default AllDevices;
