import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCompany } from '../redux/slices/globalSlice';

const useSelectedCompany = ({ companyOptions }) => {
    const dispatch = useDispatch();
    const selectedCompanyFromStore = useSelector(state => state.global.selectedCompany);
    const { role, assignedCompanies } = useSelector(state => state.global);

    const [localSelectedCompany, setLocalSelectedCompany] = useState({ name: "", id: "" });

    const handleCompanyChange = useCallback((event) => {
        const newSelectedCompanyName = event.target.value;
        const selectedOption = companyOptions.find(option => option.name === newSelectedCompanyName);

        if (selectedOption) {
            setLocalSelectedCompany({ name: selectedOption.name, id: selectedOption.id });
            dispatch(setSelectedCompany({ name: selectedOption.name, id: selectedOption.id }));
        }
    }, [companyOptions, dispatch]);

    useEffect(() => {
        let filteredCompanyOptions = companyOptions;

        if (role === 'Power Admin') {
            filteredCompanyOptions = companyOptions.filter(option => assignedCompanies.includes(option.name));
        }

        if (!selectedCompanyFromStore?.name && filteredCompanyOptions.length > 0) {
            const initialSelectedCompany = filteredCompanyOptions[0];
            setLocalSelectedCompany({ name: initialSelectedCompany.name, id: initialSelectedCompany.id });
            dispatch(setSelectedCompany({ name: initialSelectedCompany.name, id: initialSelectedCompany.id }));
        } else if (selectedCompanyFromStore?.name && filteredCompanyOptions.length > 0) {
            const selectedOption = filteredCompanyOptions.find(option => option.name === selectedCompanyFromStore.name);
            if (selectedOption) {
                setLocalSelectedCompany({ name: selectedOption.name, id: selectedOption.id });
            } else {
                const initialSelectedCompany = filteredCompanyOptions[0];
                setLocalSelectedCompany({ name: initialSelectedCompany.name, id: initialSelectedCompany.id });
                dispatch(setSelectedCompany({ name: initialSelectedCompany.name, id: initialSelectedCompany.id }));
            }
        }
    }, [selectedCompanyFromStore, companyOptions, role, assignedCompanies, dispatch]);

    const getSelectedCompanyLabel = useCallback(() => {
        return localSelectedCompany.name;
    }, [localSelectedCompany.name]);

    return {
        selectedCompany: localSelectedCompany.name,
        selectedCompanyId: localSelectedCompany.id,
        handleCompanyChange,
        getSelectedCompanyLabel,
    };
};

export default useSelectedCompany;